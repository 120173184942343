import React, { useEffect, useMemo, useState } from "react";
import { fstore } from "../firebaseobj";
import paths from "../paths.json";
import { useSortBy, useTable } from "react-table";
import { firestoreErrorHandler } from "../common";
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const Search = ({
  setIndex,
  setSearch,
  indices,
}: {
  setIndex: any;
  setSearch: any;
  indices: Array<string>;
}) => {
  return (
    <InputGroup>
      <Form.Control
        placeholder="Search..."
        onChange={(event) => {
          setSearch(event.target.value);
        }}
      />
      <InputGroup.Append>
        <Form.Control
          style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
          as="select"
          id="index-select"
          onChange={(event) => setIndex(event.target.value)}
          custom
        >
          {indices.map((ind) => (
            <option key={ind}>{ind}</option>
          ))}
        </Form.Control>
      </InputGroup.Append>
    </InputGroup>
  );
};
let Users = () => {
  let [users, setUsers] = useState([] as Array<any>);
  let [ids, setIds] = useState([] as Array<any>);
  let usersref = fstore.collection(paths.usercollection);
  const indices = ["username", "emailid", "mobile"];
  const [indexon, setIndex] = useState("username");
  const [search, setSearch] = useState("");

  function dataSetter(data: any) {
    let [users, ids]: [Array<any>, Array<any>] = [[], []];
    let userjson: any = {};
    data.forEach((doc: any) => {
      const dat = doc.data();
      if (dat[indexon].toUpperCase().indexOf(search.toUpperCase()) > -1) {
        ids.push(doc.id);
        users.push(dat);
        userjson[doc.id] = doc.data();
      }
    });
    setUsers(users);
    setIds(ids);
  }

  useEffect(() => {
    let listener = usersref
      .orderBy(indexon)
      .where("fran", "==", sessionStorage.getItem("fran"))
      .where(indexon, ">=", search.toUpperCase())
      .limit(15)
      .onSnapshot(dataSetter, firestoreErrorHandler);
    return () => listener();
  }, [indexon, search]);
  const otherheadings = useMemo(() => {
    return ["emailid", "username", "registeredsince", "fran", "mobile"].map(
      (key) => {
        return {
          Header: key.toUpperCase(),
          accessor: key,
        };
      }
    );
  }, []);
  let tab = useTable({ columns: otherheadings, data: users }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tab;
  return (
    <Container className="mt-2">
      <Row>
        <Col className="my-3">
          <Search indices={indices} setIndex={setIndex} setSearch={setSearch} />
        </Col>
      </Row>
      <Row>
        <Col>
          {" "}
          <Table
            variant="info"
            borderless={true}
            className="rounded p-2 text-gray-900"
            responsive={true}
            {...getTableProps}
          >
            <thead className="text-center" style={{ lineBreak: "strict" }}>
              {
                // Loop over the header rows
                headerGroups.map((headerGroup) => (
                  // Apply the header row props
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map((column) => (
                        // Apply the header cell props

                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <br />
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <i className="pi pi-angle-down" />
                              ) : (
                                <i className="pi pi-angle-up" />
                              )
                            ) : (
                              <>
                                <i className="pi pi-angle-up" />
                                <i className="pi pi-angle-down" />
                              </>
                            )}
                          </span>
                        </th>
                      ))
                    }
                    <th className="align-text-top">Subscription</th>
                  </tr>
                ))
              }
            </thead>
            <tbody className="text-center" {...getTableBodyProps()}>
              {
                // Loop over the table rows
                rows.map((row, index) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                      <td>
                        <Button>
                          <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={ids[index]}
                          >
                            Manage
                          </Link>
                        </Button>
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      {/*<Row>
        <Col>
          <Button
            className="float-right"
            onClick={() => setCurr(() => previous(users[0].emailid))}
          >
            {"<"}
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => setCurr(() => next(users[users.length - 1].emailid))}
          >
            {">"}
          </Button>
        </Col>
      </Row>*/}
    </Container>
  );
};
export default Users;
