import React, { SyntheticEvent, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { authobj, gprov } from "../firebaseobj";
import { Redirect } from "react-router-dom";
import firebase from "firebase";

const Login = ({ user }: { user: firebase.User | null }) => {
  let [logdis, setLog] = useState(false);
  let submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    let formdata = new FormData(e.currentTarget as HTMLFormElement);
    await authobj
      .signInWithEmailAndPassword(
        formdata.get("username") as string,
        formdata.get("password") as string
      )
      .catch((err) => {
        alert(err.message);
      });
  };
  if (user) {
    return <Redirect to="/" />;
  } else
    return (
      <Container fluid>
        <Row>
          <Col lg={{ offset: 3, span: 6 }}>
            <Form
              className="mt-3 p-4 login rounded text-white shadow border-left-success bg-gradient-dark "
              data-testid="form"
              onSubmit={submitHandler}
            >
              <Form.Group>
                <Form.Label>
                  <span className="h5">Username</span>
                </Form.Label>
                <Form.Control data-testid="user" type="email" name="username" />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <span className="h5">Password</span>
                </Form.Label>
                <Form.Control
                  data-testid="pass"
                  type="password"
                  name="password"
                />
              </Form.Group>
              <Button
                className="font-weight-bold"
                block
                data-testid="submitbtn"
                type="submit"
              >
                Login
              </Button>

              <span className="d-flex justify-content-center">
                <Button
                  className="pi pi-google mt-3 w-50"
                  disabled={logdis}
                  onClick={async (event) => {
                    setLog(true);
                    try {
                      await authobj.signInWithPopup(gprov);
                    } catch (e) {
                      console.error(e);
                      setLog(false);
                    }
                    setLog(false);
                  }}
                />
              </span>
            </Form>
          </Col>
        </Row>
      </Container>
    );
};

export default Login;
