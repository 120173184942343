import React, { useEffect, useMemo, useState } from "react";
import { InputGroup, Container, Form, Table, Button } from "react-bootstrap";
import { fstore } from "../firebaseobj";
import * as paths from "../paths.json";
import { useSortBy, useTable } from "react-table";
import { Link } from "react-router-dom";
const Orders = () => {
  //Variables
  const indices = ["id", "uid"];
  //State
  const [search, setSearch] = useState("");
  const [indexon, setIndex] = useState("id");
  const [data, setData] = useState({});

  //Sets the data after checking whether they match the search
  function dataSetter(data: any) {
    let userjson: any = {};
    data.forEach((doc: any) => {
      const dat = doc.data();
      if (dat[indexon].toUpperCase().indexOf(search.toUpperCase()) > -1) {
        userjson[doc.id] = doc.data();
      }
    });
    setData(userjson);
  }

  //Data Subscription
  useEffect(() => {
    const listener = fstore
      .collection(paths.orders)
      .orderBy(indexon)
      .where("fran", "==", sessionStorage.getItem("fran"))
      .where(indexon, ">=", search.toUpperCase())
      .limit(15)
      .onSnapshot(dataSetter);
    return () => {
      listener();
    };
  }, [search, indexon]);

  //Body
  return (
    <Container>
      <Search setSearch={setSearch} setIndex={setIndex} indices={indices} />
      <Tab data={data} />
    </Container>
  );
};

//Component for search
const Search = ({
  setSearch,
  setIndex,
  indices,
}: {
  setSearch: any;
  setIndex: any;
  indices: Array<string>;
}) => {
  return (
    <InputGroup>
      <Form.Control
        placeholder="Search..."
        onChange={(event) => setSearch(event.target.value)}
      />
      <InputGroup.Append>
        <Form.Control
          style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
          as="select"
          id="index-select"
          onChange={(event) => setIndex(event.target.value)}
          custom
        >
          {indices.map((ind) => (
            <option key={ind}>{ind}</option>
          ))}
        </Form.Control>
      </InputGroup.Append>
    </InputGroup>
  );
};

//Component for table
const Tab = (props: { data: any }) => {
  const data = props.data;
  const vals = useMemo(() => Object.values(data) as any, [data]);
  const ids = Object.keys(data);
  const nheadings = useMemo(
    () =>
      ["id", "uid", "amount", "amount_paid", "year"].map((elem) => {
        return { Header: elem.toUpperCase(), accessor: elem };
      }),
    []
  );
  let tab = useTable({ columns: nheadings, data: vals }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tab;
  return (
    <Container className="mt-2">
      <Table
        variant="info"
        borderless={true}
        className="rounded p-2 text-gray-900"
        responsive={true}
        {...getTableProps}
      >
        <thead className="text-center">
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props

                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {/* Add a sort direction indicator */}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <i className="pi pi-angle-down" />
                          ) : (
                            <i className="pi pi-angle-up" />
                          )
                        ) : (
                          <>
                            <i className="pi pi-angle-up" />
                            <i className="pi pi-angle-down" />
                          </>
                        )}
                      </span>
                    </th>
                  ))
                }
                <th>Subscription</th>
              </tr>
            ))
          }
        </thead>
        <tbody className="text-center" {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, index) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            String(cell.value)
                          }
                        </td>
                      );
                    })
                  }
                  <td>
                    <Button>
                      <Link
                        style={{ textDecoration: "none", color: "inherit" }}
                        to={"/" + ids[index]}
                      >
                        Manage
                      </Link>
                    </Button>
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </Table>
    </Container>
  );
};

export default Orders;
