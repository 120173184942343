import React, { ReactNode, useEffect, useState } from "react";
import { authobj } from "../firebaseobj";
import firebase from "firebase";
export const AuthContext = React.createContext({
  userstate: authobj.currentUser,
});
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  let [userstate, setUser] = useState(authobj.currentUser);
  useEffect(() => {
    //TODO: Check if there is an alternative to using sessionStorage
    let stuff = authobj.onAuthStateChanged(async (user) => {
      if (user) {
        const claims = (await user.getIdTokenResult()).claims;
        console.info(claims);
        if (!claims.fran) {
          alert("You do not have an approved franchise!");
          sessionStorage.removeItem("wasloggedin");
          await authobj.signOut();
          return;
        } else sessionStorage.setItem("fran", claims.fran);
        await authobj.setPersistence(firebase.auth.Auth.Persistence.SESSION);
        sessionStorage.setItem("wasloggedin", "true");
        setUser(user);
      } else {
        sessionStorage.removeItem("wasloggedin");
        setUser(user);
      }
    });
    return () => {
      stuff();
    };
  }, []);
  return (
    <AuthContext.Provider value={{ userstate }}>
      {children}
    </AuthContext.Provider>
  );
};
