import React, { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import paths from "../paths.json";
import { fstore, funcs } from "../firebaseobj";
let SureButton = ({
  sure,
  children,
}: {
  sure: () => void;
  children: (setter: any) => ReactNode;
}) => {
  let [surevisible, setSureVisible] = useState(false);

  if (surevisible) {
    return (
      <Button
        variant="warning"
        onBlur={() => setSureVisible(false)}
        onClick={(event) => {
          event.preventDefault();
          sure();
          setSureVisible(false);
        }}
      >
        Sure?
      </Button>
    );
  } else {
    return <>{children(setSureVisible)}</>;
  }
};
let UsersManage = () => {
  let [banned, setBanned] = useState(false);
  let [exttime, setExtend] = useState(0);
  let [user, setUser] = useState(null as any);
  let [subscription, setSub] = useState(null as any);
  let uid = document.URL.substring(document.URL.lastIndexOf("/") + 1);
  useEffect(() => {
    let userouterref = fstore.collection(paths.usercollection);
    let userref = userouterref.doc(uid);
    let subref = userouterref.doc(uid + "/extra/subscription");
    let userSubscription = userref.onSnapshot(
      (user) => {
        if (user.exists) setUser(user.data() as any);
      },
      (error) => console.error(error)
    );
    let subrefSubscription = subref.onSnapshot(
      (doc) => {
        if (doc.exists) setSub(doc.data() as any);
      },
      (error) => console.error(error)
    );

    return () => {
      userSubscription();
      subrefSubscription();
    };
  }, []);
  function isEmpty(obj: any) {
    for (var x in obj) {
      return false;
    }
    return true;
  }

  if (user && !isEmpty(user) && subscription && !isEmpty(subscription)) {
    let keys = Object.keys(user)
      .filter((ke) => ["username"].indexOf(ke) === -1)
      .sort();
    keys.unshift("username");
    let data = keys.map((key) => {
      return (
        <Form.Group key={key} controlId={key}>
          <Form.Label>
            <span className="text-capitalize">{key}</span>
          </Form.Label>
          <Form.Control type="text" value={user[key]} readOnly={true} />
        </Form.Group>
      );
    });
    let subdata = (
      <>
        {" "}
        <Form.Group>
          <Form.Label>
            <span className="text-capitalize">Days Left</span>
          </Form.Label>
          <Form.Control
            type="text"
            isInvalid={(subscription.endtime - Date.now()) / 86400000 < 60}
            value={
              subscription.endtime - Date.now() > 0
                ? ((subscription.endtime - Date.now()) / 86400000).toFixed(0)
                : 0
            }
            readOnly={true}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <span className="text-capitalize">Franchise</span>
          </Form.Label>
          <Form.Control type="text" value={subscription.fran} readOnly={true} />
        </Form.Group>
      </>
    );
    return (
      <Container>
        <Row>
          <Col>
            <Card className="shadow">
              <Card.Header className="bg-gray-100	text-primary">
                <b>User</b>
              </Card.Header>
              <Card.Body>
                <Form
                  onSubmit={() => {
                    console.info("Submit Triggered");
                  }}
                >
                  <Form.Group controlId="subscription">
                    <Form.Label>Extend Subscription</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type="number"
                        min={0}
                        onChange={(event) =>
                          setExtend(Number(event.target.value))
                        }
                      />
                      <InputGroup.Append>
                        <SureButton
                          sure={() => {
                            let extend = funcs.httpsCallable(
                              paths.funcurls.extend
                            );
                            extend({
                              extend: exttime * 86400 * 30 * 1000,
                              uid: uid,
                            }).catch((err) => {
                              alert(err.message);
                              console.error(err);
                            });
                          }}
                        >
                          {(setter) => (
                            <Button
                              onClick={(event) => {
                                event.preventDefault();
                                setter(true);
                              }}
                              variant="outline-secondary"
                            >
                              Extend
                            </Button>
                          )}
                        </SureButton>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                  <Container fluid={true}>
                    <Row>
                      <Col className="text-center ">
                        <SureButton sure={() => setBanned(!banned)}>
                          {(setter) => (
                            <Button
                              variant={
                                (banned ? "primary" : "danger") + " w-50"
                              }
                              onClick={() => setter(true)}
                            >
                              {banned ? "Unban" : "Ban"}
                            </Button>
                          )}
                        </SureButton>
                      </Col>
                    </Row>
                  </Container>
                  <>{subdata}</>

                  <>{data}</>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  } else return <div>No suitable user provided</div>;
};

export default UsersManage;
