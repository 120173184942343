import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { authobj, fstore } from "../firebaseobj";
import path from "../paths.json";
import { ExtraParams } from "../Interfaces";
//import PieChart from "./Charts/PieChart";
//import Graph from "./Charts/AnimatedLine";

let Main = () => {
  let [extraparamsdoc, setExtraParams] = useState({} as ExtraParams);

  useEffect(() => {
    let extraparams = fstore.doc(`${path.frans}/${authobj.currentUser?.uid}`);
    let unsubscribe = extraparams.onSnapshot(
      (data) => {
        if (data.exists) setExtraParams(data.data() as ExtraParams);
      },
      (err) => {
        console.error(err);
      }
    );
    return () => {
      unsubscribe();
    };
  }, [authobj.currentUser?.uid]);
  let payments = () => {
    if (extraparamsdoc.payments && extraparamsdoc.payments.length) {
      return (
        <ul style={{ paddingLeft: "20px" }}>
          {extraparamsdoc.payments.map((elem) => {
            return <li>₹{Number(elem) / 100}</li>;
          })}
        </ul>
      );
    } else return "NA";
  };
  return (
    <Container>
      <Row>
        <Col className="h4 text-center text-monospace text-gray-900 font-weight-bold mt-5">
          Dashboard
        </Col>
      </Row>
      <Row>
        <Col md className="mb-2">
          <Card className="shadow border-left-primary">
            <Card.Body>
              <Container>
                <Row className="text-xs font-weight-bold text-uppercase text-primary">
                  <Col>User Count</Col>
                </Row>
                <Row>
                  <Col className="h3 font-weight-bold">
                    {extraparamsdoc.users_reg ?? "NA"}
                  </Col>
                  <Col>
                    <i className="float-right pi pi-users h3" />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>

        <Col md className="mb-2">
          <Card className="shadow border-left-success">
            <Card.Body>
              <Container>
                <Row className="text-xs font-weight-bold text-uppercase text-success">
                  <Col>Revenue</Col>
                </Row>
                <Row>
                  <Col className="h3 font-weight-bold">
                    {extraparamsdoc.revenue
                      ? "₹" + (extraparamsdoc.revenue / 1000) * 3
                      : "NA"}
                  </Col>
                  <Col>
                    <i className="float-right pi pi-money-bill h3" />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="shadow border-left-warning">
            <Card.Body>
              <Container>
                <Row className="text-xs font-weight-bold text-uppercase text-warning  ">
                  <Col>Payments</Col>
                </Row>
                <Row>
                  <Col className="h5 font-weight-bold">{payments()}</Col>
                  <Col>
                    <i className="float-right pi pi-money-bill h3" />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/*   <Row>
        <Col className="my-2">
          <PieChart />
        </Col>

        <Col className="my-2">
          <Card>
            <Card.Header className="bg-dark text-light">Dataset 2</Card.Header>
            <Card.Body>
              <Graph />
            </Card.Body>
          </Card>
        </Col>
      </Row>*/}
    </Container>
  );
};
export default Main;
